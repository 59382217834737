import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { IClient, IResultClients } from '@core/interfaces/clients.interface';
import { environment } from '@environments/environment';
import { map } from 'rxjs';
import { FechaPipe } from '@core/pipes/fecha.pipe';
import { xClient } from '@core/interceptors/auth.interceptor';

interface State {
  clients: any[],
  loading: boolean
}

interface Filters {
  'id': string;
  'status': string;
  'page': string; //1
  'from': string;
  'to': string;
  'customer': string;
  'doctor': string;
  'size': string; //10
  'sort': string; //'CREATED_DESC'
}

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  private http = inject(HttpClient);
  private datePipe = inject(FechaPipe);

  endpoint: string = 'clients';

  #state = signal<State>({
    loading: true,
    clients: []
  })

  searchItem = signal<string>('');

  filters = signal<Filters | undefined>(
    {
      id: '',
      status: '',
      page: '1',
      from: '',
      to: '',
      customer: '',
      doctor: '',
      size: '10',
      sort: 'CREATED_DESC',
    }
  );

  public clients = computed(() => this.#state().clients);
  public loading = computed(() => this.#state().loading);

  setState(clients: any){
    this.#state.set({
      loading: false,
      clients
    })
  }

  clearFilters(){
    this.filters.set(undefined)
  }
  resetFilters(){
    this.filters.set(
      {
        id: '',
        status: '',
        page: '1',
        from: '',
        to: '',
        customer: '',
        doctor: '',
        size: '10',
        sort: 'CREATED_DESC',
      }
    )
  }

  getClients(){
    this.endpoint = 'clients';
    let params = new HttpParams({ fromObject: Object.assign(this.filters()) });
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, { params }).pipe(
        map((res: IResultClients) => {
          res.result.map(c => {
            c['created'] = `${this.datePipe.transform(c.createdDate)}`;
            c['entireName'] = `${c.name} ${c['lastName']}`;
            if(c.street){
              c['postalDirection'] = `${c.street} ${c.streetNumber} ${c.apartment ? c.apartmentNumber : ''}, CP ${c.zipCode}`;
            } else {
              c['postalDirection'] = `Sin dirección cargada`;
            }
          })
          return res;
        })
    );
  }

  getClient(id: number){
    this.endpoint = 'clients';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, {
      params: new HttpParams()
          .set('client_id', `${id}`)
    }).pipe(
        map((res: IResultClients) => res.result)
    );
  }

  getClientDetail(id: number){
    this.endpoint = `clients/${id}/treatments`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`).pipe(
      map(res => res)
    )
  }

  getProspectId(id: number){
    this.endpoint = `clients/${id}/prospects`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`).pipe(
      map(res => res)
    )
  }

  getOriginClient(email: string){
    this.endpoint = `clients/emails/sources`;
    const headers = new HttpHeaders({
			'userName': email,
		})
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, {
      headers,
      context: xClient()
    }).pipe(
      map((res: any) => {
        return res.source;
      })
    )
  }

  updateMedicineComboClient(clientId: number, treatmentId: number, updateClient: any){
    this.endpoint = `clients/${clientId}/treatments/${treatmentId}`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, updateClient).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  updateDoctorClient(idClient: number, idTreatment: number, updateClient: any){
    this.endpoint = `clients/${idClient}/treatment/${idTreatment}`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, updateClient).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateComboClient(idClient: number, idTreatment: number, updateClient: any){
    this.endpoint = `clients/${idClient}/treatment/${idTreatment}`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, updateClient).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateMedicineClient(idClient: number, idTreatment: number, updateClient: any){
    this.endpoint = `clients/${idClient}/treatment/${idTreatment}`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, updateClient).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateFPP(idClient: number, idTreatment: number, updateClient: any){
    this.endpoint = `clients/${idClient}/treatment/${idTreatment}`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, updateClient).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateClient(clientEdited: any, selectedClient: IClient){
    this.endpoint = 'clients';
    let newClient: IClient = {...selectedClient};

    newClient.apartment = clientEdited.client.apartment;
    newClient.apartmentNumber = clientEdited.client.apartmentNumber;
    newClient.additionalInformation = clientEdited.client.additionalInformation;
    newClient.birthSex = clientEdited.client.birthSex;
    newClient.city = clientEdited.client.city;
    newClient.province = clientEdited.client.province;
    newClient.colony = clientEdited.client.colony;
    newClient.dni = clientEdited.client.dni;
    newClient.email = clientEdited.client.email;
    newClient.healthInsuranceCode = clientEdited.client.healthInsuranceCode;
    newClient.healthInsuranceName = clientEdited.client.healthInsuranceName;
    newClient.healthInsuranceNumber = clientEdited.client.healthInsuranceNumber;
    newClient.healthInsurancePlan = clientEdited.client.healthInsurancePlan;
    newClient.name = clientEdited.client.name;
    newClient.lastName = clientEdited.client.lastName;
    newClient.selfPerceivedGender = clientEdited.client.selfPerceivedGender;
    newClient.street = clientEdited.client.street;
    newClient.streetNumber = clientEdited.client.streetNumber;
    newClient.telephone = clientEdited.client.telephone;
    newClient.zipCode = clientEdited.client.zipCode;
    newClient.prospectId = clientEdited.client.prospectId;

    return this.http.put(`${environment.apiUrl}/${this.endpoint}/${newClient.id}`, newClient).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  setStartDate(data: any){
    this.endpoint = `clients/treatments/start-date`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, data).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  enableSuscription(idUser: number, idTreatment: number){
    this.endpoint = `clients/${idUser}/treatment/${idTreatment}/subscription/enable`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, null )
      .pipe(
        map((response: any) => {
          response = true;
          return response;
        })
      )
  }

  disableSuscription(idUser: number, idTreatment: number, respuestas: any){
    this.endpoint = `clients/${idUser}/treatment/${idTreatment}/subscription/disable`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, respuestas )
      .pipe(
        map((response: any) => {
          response = true;
          return response;
        })
    );
  }

  updateTreatment(idUser: number, idTreatment: number, treatment: any){
    this.endpoint = `clients/${idUser}/treatment/${idTreatment}`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, treatment)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  setDataToSheet(type: string, data: any){
    this.endpoint = `sheets/${type}`;
    return this.http.post(`${environment.webApiUrl}/${this.endpoint}`, data).pipe(
      map((response: any) => {
        return response;
      })
    )
  }



}
